<template>
    <div>
        <v-container grid-list-md text-xs-center fluid>
            <h1>Checkin</h1>
            <v-divider class="mb-4" />

            <v-alert border="bottom" colored-border icon="search" :color="items && items.length > 0 ? 'grey' : 'error'" outlined>
                <v-layout wrap>
                    <v-flex xs12 md6>
                        <v-autocomplete v-model="selectedStore" :items="stores" item-text="name" item-value="name" label="Loja" return-object :disabled="!loaded" :loading="!loaded"
                            clearable />
                    </v-flex>
                    <v-flex xs12 md5>
                        <DateInterval today :label="$t('Data')" @onChange="dates = $event" />
                    </v-flex>
                    <v-flex xs12 md1 mt-4 text-center>
                        <v-btn color="success" @click="getItems" small>Buscar</v-btn>
                    </v-flex>
                </v-layout>
            </v-alert>
            <v-layout wrap class="my-12">
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ active }}
                            </div>
                            Especialistas Ativos
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ open }}
                            </div>
                            Finalizados
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm4>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ openInPast }}
                            </div>
                            <div class="d-flex flex-no-wrap justify-space-between">
                                Checkout abertos
                                <v-btn x-small v-if="openInPast > 0" @click="dialog = true" color="success">{{ $t("Ver lista") }}</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>

        <SimpleTable formclass="Checkin/form" endpoint="checkin" :items="items" @needReload="search" :updateTime="30" :aditionalColumns="headers" :hideNew="!isAdmin"
            :hideEdit="!isAdmin" :hideDelete="!isAdmin" :itemsPerPage="-1">
            <template v-slot:extra-column="item">
                <div v-if="item.dateIn || item.dateOut" class="text-center">
                    <v-chip class="ma-0" outlined color="success" v-if="item.dateIn"> {{ $moment(item.dateIn).format("DD/MM/YY HH:mm") }} </v-chip>
                    <v-chip class="ma-0" outlined color="error" v-else> Aberto </v-chip>

                    <v-chip class="ma-0" outlined color="success" v-if="item.dateOut"> {{ $moment(item.dateOut).format("DD/MM/YY HH:mm") }} </v-chip>
                    <v-chip class="ma-0 px-1" outlined color="error" v-else>Aguardando saída</v-chip>
                </div>
                <v-chip class="ma-0" outlined color="error" v-else> Aguardando checkin {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }} </v-chip>
            </template>

            <template v-slot:more-extra-column="item">
                <v-chip class="ma-0" outlined color="grey" v-for="i in item.modals.filter((e) => e.modal != null)" :key="i"> {{ i.modal }} </v-chip>
            </template>
        </SimpleTable>

        <div class="text-center">
            <v-dialog v-model="dialog" scrollable width="500">
                <v-card>
                    <v-card-title class="text-h5 white--text primary"> Listagem de pendências de checkout </v-card-title>

                    <v-card-text>
                        <v-simple-table>

                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Especialista</th>
                                        <th class="text-left">Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in itemsWithError" :key="item.id">
                                        <td>{{ item.expert }}</td>
                                        <td>
                                            <v-chip class="ma-0" outlined color="success" v-if="item.dateIn"> {{ $moment(item.dateIn).format("DD/MM/YY HH:mm") }} </v-chip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import DateInterval from "@/components/core/DateInterval";
import SimpleTable from "@/components/core/SimpleTable";
import { mapGetters } from "vuex";

export default {
    components: {
        DateInterval,
        SimpleTable,
    },

    created() {
        this.$http
            .get('stores?enable=true')
            .then((result) => {
                this.stores = result;
                this.loaded = true;
            })
            .catch(() => {
                this.stores = [];
            });
    },

    data() {
        return {
            dialog: false,
            headers: [
                { text: this.$t("Modal"), value: "more-extra-column", align: "right", sortable: true },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: true },
                { text: this.$t("Especialista"), value: "expert", align: "right", sortable: true },
                { text: this.$t("Status - Inicio"), value: "extra-column", align: "right", sortable: true },
            ],
            isAdmin: false,
            items: [],
            itemsWithError: [],
            loaded: false,
            dates: [],
            stores: [],
            selectedStore: null,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
        this.search();
    },

    methods: {
        search() {
            this.getItems();
            this.getItemsWithError();
        },
        getItems() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedStore) {
                query += `&store=${this.selectedStore.id}`;
            }

            this.$http
                .get(`checkin${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
        getItemsWithError() {
            this.$http
                .get(`checkin/with-error`)
                .then((result) => (this.itemsWithError = result))
                .catch(() => (this.itemsWithError = []));
        },
    },

    computed: {
        ...mapGetters(["verifyRole"]),
        active() {
            return Number.parseFloat(this.items.reduce((sum, i) => (!i.dateOut ? sum + 1 : sum), 0)).toFixed(0);
        },
        open() {
            return Number.parseFloat(this.items.reduce((sum, i) => (i.dateOut ? sum + 1 : sum), 0)).toFixed(0);
        },
        openInPast() {
            return this.itemsWithError ? this.itemsWithError.length : 0;
        },
    },
};
</script>